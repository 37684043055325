import React from "react";
import Slider from "react-slick";
import "./style.css";

import Card from "../CardRow/Card";

const Car = (props) => {
  const { cards, direction } = props;
  const rev = direction && direction > 0;

  const settings = {
    arrows: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: direction,
    infinite: true,
    autoplay: true,
    speed: 12000,
    autoplaySpeed: 0,
    swipe: true,
    swipeToSlide: true,
    cssEase: "linear",
    easing: "ease",
  };
  return (
    <div style={{ position: "relative", marginBottom: "24px" }}>
      <Slider {...settings}>
        {rev
          ? cards.map((card) => <Card key={Math.random()} card={card} />)
          : cards
              .slice(0)
              .reverse()
              .map((card) => <Card key={Math.random()} card={card} />)}
      </Slider>
    </div>
  );
};

export default Car;

//  pauseOnHover: true,

// speed: 12000,
// autoplaySpeed: 0,
