import "./App.css";
import React, { useState } from "react";
import { HashLink, NavHashLink } from "react-router-hash-link";
// import { Route, Switch, useLocation } from "react-router-dom";

// components
import MobileMenu from "./comps/MobileMenu";
import Footer from "./comps/Footer";
import Landing from "./sections/Landing";
import About from "./sections/About";
import Portfolio from "./sections/Portfolio";
import Services from "./sections/Services";
import Clients from "./sections/Clients";
import Contact from "./sections/Contact";

const App = () => {
  const [navState, setnavState] = useState(false);
  const [aboutPosition, setAboutPosition] = useState(0);
  const [porfolioPostion, setPorfolioPostion] = useState(0);
  const [servicesPosition, setServicesPosition] = useState(0);
  const [clientPosition, setClientPosition] = useState(0);
  const [contactPostion, setContactPostion] = useState(0);
  const [subject, setSubject] = useState(null);

  const positionArr = [
    aboutPosition,
    porfolioPostion,
    servicesPosition,
    clientPosition,
    contactPostion,
  ];

  return (
    <div className="App">
      <MobileMenu drop={navState} setDrop={setnavState} />
      <Landing drop={setnavState} position={positionArr} />
      <About set={setAboutPosition} position={positionArr} drop={setnavState} />
      <Portfolio
        set={setPorfolioPostion}
        position={positionArr}
        drop={setnavState}
      />
      <Services
        set={setServicesPosition}
        position={positionArr}
        drop={setnavState}
        setSubject={setSubject}
      />
      <Clients
        set={setClientPosition}
        position={positionArr}
        drop={setnavState}
      />
      <Contact
        set={setContactPostion}
        position={positionArr}
        drop={setnavState}
        subject={subject}
        setSubject={setSubject}
      />
      <Footer />
    </div>
  );
};

export default App;
