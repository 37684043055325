import React from "react";

const Icon = (props) => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" />
    </svg>
  );
};

export default Icon;
