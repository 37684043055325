import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 159 115">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M60.282 26.78c-1.757.928-2.842 2.43-3.127 4.328-.457 3.046 1.307 6.04 4 6.787 5.431 1.508 9.493-1.925 8.592-7.26-.644-3.809-5.67-5.856-9.465-3.854zM39.785 50.513v24.17h11.257v-48.34H39.785v24.17zm-39.4.993v23.177h12.25V58.127H31.84V47.201H12.635v-7.946h21.19V28.33H.385v23.176zm92.435-9.084c-2.232.601-4.39 1.862-5.659 3.307l-.99 1.128-.1-1.897-.099-1.896-4.883-.092-4.884-.091v46.37h10.926v-8.222c0-4.522.088-8.31.197-8.419.108-.108.972.3 1.919.906 3.325 2.126 7.67 2.725 11.908 1.64 2.701-.693 4.331-1.683 6.442-3.915 2.078-2.197 3.473-5.048 4.181-8.546.649-3.211.648-4.617-.008-7.793-1.376-6.664-5.156-10.99-10.91-12.49-2.396-.624-5.7-.62-8.04.01zM57.995 58.79v15.892h11.257V42.897H57.995V58.79zm39.636-6.412c2.084 1.675 2.911 3.503 2.901 6.412-.011 3.265-1.1 5.514-3.293 6.799-2.964 1.737-7.126.86-9.145-1.927-.92-1.27-.963-1.489-.963-4.845 0-3.19.076-3.627.816-4.716.479-.706 1.535-1.564 2.566-2.086 1.464-.74 2.086-.866 3.807-.765 1.695.099 2.278.298 3.311 1.128z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#fff"
        d="M121.028 73.95h0c-1.433-.463-2.342-1.258-3.013-2.644-.282-.582-.432-1.002-.504-1.464-.073-.47-.069-1.013-.002-1.85.143-1.781.657-2.96 1.529-3.706.881-.754 2.218-1.146 4.182-1.146 2.018 0 3.355.39 4.223 1.155.862.759 1.359 1.973 1.493 3.853l.499-.035-.499.035c.095 1.33-.022 2.321-.354 3.112-.328.781-.885 1.412-1.755 1.988h0c-.611.405-1.653.719-2.787.857-1.13.138-2.254.09-3.012-.155zm18.456-39.726h0c.499-.126 2.844-.709 5.212-1.295 1.18-.291 2.288-.548 3.121-.726a29.797 29.797 0 011.048-.208c.039.25.078.62.113 1.1.072.99.128 2.408.155 4.125l.09 5.685.008.492h8.593l-.081 4.042-.083 4.073-3.742.082-4.221.093-.489.01V58.285c0 2.954.004 4.522.109 5.456.053.477.137.828.278 1.132.142.303.328.526.521.75h0c.372.433.71.742 1.264.93.507.172 1.175.233 2.167.29 1.282.072 2.956-.027 3.742-.244h0c.293-.081.566-.148.785-.195.01.206.02.468.03.777.025.79.049 1.87.064 3.048l.052 3.896-1.887.458h0c-1.203.293-3.601.55-5.305.562-5.69.041-9.362-1.677-10.97-4.819l-.943-1.843-.103-8.088-.103-8.206-.006-.475-.475-.018-2.545-.097-2.074-.078-.082-4.081-.082-4.042h5.306v-9.038l.533-.135zm9.7-2.273h-.007s0 0 0 0h.007zm-31.092 9.996V26.554h10.257V57.34h-10.257V41.947z"
      ></path>
    </svg>
  );
}

export default Icon;
