import React from "react";
import "./style.css";

//comps
import Text from "./Text";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import Dots from "../../comps/Dots";

const Section = (props) => {
  return (
    <div className="landing-media-wrap">
      <Text />
      <Desktop />

      <Dots className="landing-dots-1" height={8} width={8} />
    </div>
  );
};

export default Section;
