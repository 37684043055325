import React from "react";
import "./style.css";

//comps
import Header from "../../comps/Header";
import SectionWrap from "../../comps/SectionWrap";
import SectionHead from "../../comps/SectionHead";

//media
import Wad from "../../media/logos/wolfeAD.js";
import Bl from "../../media/logos/baselayer.js";
import Vw from "../../media/logos/vwStaking.js";
import Flip from "../../media/logos/flipit.js";
import Texxture from "../../media/logos/texxture.js";

const Section = (props) => {
  return (
    <SectionWrap
      set={props.set}
      id="clients"
      className="flex-just-cent blue-section"
    >
      <Header position={props.position} drop={props.drop} />
      <div>
        <SectionHead text="Top" hilght="Clients" />
        <p
          style={{
            maxWidth: "600px",
            margin: "24px auto 0",
            color: "white",
            textAlign: "center",
          }}
        >
          I have had the pleasure of working with great brands across many
          different industries. Below are a few great examples.
        </p>
        <div className="clients-logos-wrap flex-align-cent flex-just-cent">
          <a href="http://www.texxturehome.com/" target="_blank">
            <Texxture />
          </a>

          <a href="https://us-flipit.com/" target="_blank">
            <Flip />
          </a>
          <a href="https://vwstaking.com/" target="_blank">
            <Vw />
          </a>
          <a href="http://www.baselayer.io/" target="_blank">
            <Bl />
          </a>
          <a href="https://www.wolfead.net/" target="_blank">
            <Wad />
          </a>
        </div>
        <div className="client-logo-hint flex-align-cent flex-just-cent">
          <svg viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M10,9A1,1 0 0,1 11,8A1,1 0 0,1 12,9V13.47L13.21,13.6L18.15,15.79C18.68,16.03 19,16.56 19,17.14V21.5C18.97,22.32 18.32,22.97 17.5,23H11C10.62,23 10.26,22.85 10,22.57L5.1,18.37L5.84,17.6C6.03,17.39 6.3,17.28 6.58,17.28H6.8L10,19V9M11,5A4,4 0 0,1 15,9C15,10.5 14.2,11.77 13,12.46V11.24C13.61,10.69 14,9.89 14,9A3,3 0 0,0 11,6A3,3 0 0,0 8,9C8,9.89 8.39,10.69 9,11.24V12.46C7.8,11.77 7,10.5 7,9A4,4 0 0,1 11,5Z"
            />
          </svg>
          Click a logo to view site
        </div>
      </div>
    </SectionWrap>
  );
};

export default Section;
