// import * as firebase from "firebase/app";
import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBYW-0wwL7uqsBEScjbAbO1eThBEs1nO94",
  authDomain: "matts-web-dev.firebaseapp.com",
  projectId: "matts-web-dev",
  storageBucket: "matts-web-dev.appspot.com",
  messagingSenderId: "455088427584",
  appId: "1:455088427584:web:8f5a38feca043331a9c5a5",
  measurementId: "G-B0P5VXKGRG",
};
firebase.initializeApp(firebaseConfig);

// export const db = firebase.firestore();
export default firebase;
