import React from "react";
import "./style.css";
// import Tilt from "react-parallax-tilt";

const Row = (props) => {
  return (
    <div className="portfolio-card">
      <a target="_blank" href={props.card.link}>
        <img src={props.card.img} alt={`Matt Wolfe ${props.card.alt}`} />
      </a>
    </div>
  );
};

export default Row;

//src={props.card.img}

// <Tilt className="parallax-effect" perspective={1000} tiltReverse={true}>
//   <div className="portfolio-card">
//     <a target="_blank" href={props.card.link}>
//       <img src={props.card.img} alt={`Matt Wolfe ${props.card.alt}`} />
//     </a>
//   </div>
// </Tilt>
