import React from "react";

import "./style.css";

const Footer = () => {
  return (
    <footer className="flex-just-cent">
      <div className="flex-align-cent flex-just-btw header-content-wrap">
        <div>
          © Matt wolfe, inc {new Date().getFullYear()}, All rights reserved
        </div>
        <div className="flex-align-cent footer-social-links">
          <a
            href="https://www.linkedin.com/in/matt-wolfe-622610126/"
            className="flex-align-cent flex-just-cent"
          >
            <svg viewBox="0 0 24 24">
              <path d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
            </svg>
          </a>

          <a href="https://github.com/mwoof">
            <svg viewBox="0 0 24 24">
              <path d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z" />
            </svg>
          </a>
          <a href="https://www.behance.net/mattwolfe">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="#fff"
                d="M22 7h-7V5h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14H15.97c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988H0V5.021h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zM3 11h3.584c2.508 0 2.906-3-.312-3H3v3zm3.391 3H3v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"
              ></path>
            </svg>
          </a>
          <a href="https://dribbble.com/mwolfe">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 32 32"
            >
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M16 3C8.823 3 3 8.823 3 16s5.823 13 13 13c7.163 0 13-5.823 13-13S23.163 3 16 3zm8.587 5.992c1.55 1.89 2.481 4.3 2.51 6.91-.367-.071-4.033-.818-7.727-.353-.085-.183-.155-.38-.24-.578a33.027 33.027 0 00-.733-1.608c4.089-1.663 5.95-4.06 6.19-4.37zM16 4.918c2.82 0 5.4 1.057 7.36 2.791-.197.282-1.875 2.524-5.823 4.005-1.819-3.342-3.835-6.077-4.145-6.5A11.292 11.292 0 0116 4.918zM11.277 5.96c.296.395 2.27 3.144 4.117 6.415-5.189 1.382-9.771 1.354-10.265 1.354.72-3.44 3.046-6.303 6.148-7.769zM4.889 16.014v-.338c.48.014 5.866.084 11.407-1.58.324.62.62 1.255.902 1.89-.14.042-.296.084-.437.127-5.724 1.847-8.77 6.895-9.023 7.318a11.078 11.078 0 01-2.849-7.417zM16 27.111c-2.566 0-4.935-.875-6.81-2.34.197-.41 2.453-4.753 8.714-6.938.028-.014.042-.014.07-.028 1.565 4.046 2.2 7.444 2.369 8.417a10.92 10.92 0 01-4.343.889zm6.19-1.904c-.113-.677-.705-3.92-2.158-7.91 3.483-.55 6.529.353 6.91.48-.48 3.087-2.256 5.752-4.752 7.43z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
          <a href="mailto:matthew.wolfe5@gmail.com">
            <svg viewBox="0 0 24 24">
              <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
