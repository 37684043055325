import React, { useState, useEffect, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import "./style.css";

const Landing = (props) => {
  const ref = useRef(null);

  const handleScroll1 = () => {
    const top = ref.current.getBoundingClientRect().top;
    props.set(top);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll1, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll1);
    };
  }, []);

  return (
    <section ref={ref} id={props.id} className={props.className}>
      {props.children}
    </section>
  );
};

export default Landing;
