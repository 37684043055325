import React, { useLayoutEffect, useRef, useState } from "react";
import "./style.css";

//comps
import Header from "../../comps/Header";
import Tag from "../../comps/Tag";
import Dots from "../../comps/Dots";
import proPic from "../../media/mwolfe.jpg";
import Icons from "../../media/langBack";
import SectionWrap from "../../comps/SectionWrap";

const Section = (props) => {
  const skills = [
    {
      title: "Building Websites",
      des:
        "Websites generate sales and showcase your brand. Let me help you build or update one using modern UI/UX practices.",
    },
    {
      title: "API Construction",
      des:
        "Integration is the name of the game these days. I have ample experiments building robust Application Programming Interfaces (API).",
    },
    {
      title: "Machine learning",
      des:
        "Get a leg up on your competition using Machine Learning to identify trends and automate tasks. I can help you train and deploy these models.",
    },
    {
      title: "Developing Apps",
      des:
        "I have experience building apps on Android and IOs although I prefer the more modern Progressive Webs Apps (PWA)",
    },
    {
      title: "Cloud Infrastructure",
      des:
        "Going serverless can save you time and money. I can help you take your business to the cloud (Amazon Web Services, Google Cloud, Microsoft Azure).",
    },
    {
      title: "Upgrading WordPress",
      des:
        "WordPress accounts for over 60% of all websites. Upgrade your WordPress site to keep up with modern trends and designs.",
    },
    {
      title: "Graphic Design",
      des:
        "My affinity for good design sets me apart from other full stack developers. Let me help you build the best media for your project.",
    },
    {
      title: "Branding",
      des:
        "Your Brand is the face of your company. I can help you make sure it has a lasting impression.",
    },
    {
      title: "Digital Marketing",
      des:
        "Growing your business can be difficult. I can help you navigate and capitalize on online traffic.",
    },
    {
      title: "SEO Ranking",
      des:
        "Getting to the top of Googles search results can generate huge returns. Let me optimize your website to do this.",
    },
    {
      title: "Consulting",
      des:
        "Getting the best information and guidance can save you time… and money.",
    },
    {
      title: "And Many More...",
      des: "MORE",
    },
  ];
  const langs = [
    { title: "React", des: "" },
    { title: "Angular", des: "" },
    { title: "Node", des: "" },
    { title: "flutter", des: "" },
    { title: "Swift ", des: "" },
    { title: "Python", des: "" },
    { title: "Typescript", des: "" },
    { title: "JavaScripts", des: "" },
    { title: "Express", des: "" },
    { title: "php", des: "" },
    { title: "C#", des: "" },
    { title: "ASP.NET", des: "" },
    { title: "Ruby", des: "" },
    { title: "Dino", des: "" },
    { title: "Vue", des: "" },
    { title: "Java", des: "" },
    { title: "Liquid", des: "" },
    { title: "css", des: "" },
    { title: "HTML", des: "" },
    { title: "And Many More...", des: "MORE" },
  ];

  const [show, doShow] = useState({
    text: false,
    image: false,
  });
  const ref1 = useRef(null),
    ref2 = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const textPos = topPos(ref1.current),
      imgPos = topPos(ref2.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + 0.8 * window.innerHeight;
      if (textPos < scrollPos) doShow((state) => ({ ...state, text: true }));
      if (imgPos < scrollPos) doShow((state) => ({ ...state, image: true }));
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <SectionWrap set={props.set} id="about" className=" flex-just-cent">
      <Header position={props.position} drop={props.drop} />
      <div>
        <div>
          <div className="about-content-wrapper">
            <div className="about-content-wrapper-media">
              <img
                className={` about-img ${show.text ? "about-image-show" : ""}`}
                ref={ref2}
                src={proPic}
                alt="Matt Wolfe"
              />
              <Dots
                className="about-profile-dots"
                height={8}
                width={8}
                color="#2a46ff"
              />
            </div>
            <div
              ref={ref1}
              className={`about-content-wrapper-text ${
                show.text ? "about-text-show" : ""
              }`}
            >
              <span className="about-profile-title fade-in-up">
                Hey there, <br /> My name is{" "}
                <h1 style={{ color: "#2a46ff" }}>Matt Wolfe</h1>
              </span>
              <h2 className="fade-in-up">Full-stack Developer & Designer</h2>
              <p className="fade-in-up">
                I am a full stack developer based in Nashville Tennessee. While
                I primarily focus on cloud-first web and mobile apps, I am happy
                to tackle whatever new and exciting project you are pursuing. I
                am equally happy designing and developing front-ends as well as
                back-ends. My affinity for beautiful design as well as my
                experience in performant back-end solutions sets me apart from
                other full stack developers.
              </p>
              <div
                className="fade-in-up"
                style={{
                  width: "36px",
                  borderBottom: "4px solid #E1E4E7",
                  margin: "12px 0 24px",
                }}
              />
              <div className="flex-align-cent social-wrapper fade-in-up">
                <a href="https://www.linkedin.com/in/matt-wolfe-622610126/">
                  <svg viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                    />
                  </svg>
                </a>

                <a href="https://github.com/mwoof">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="#fff"
                      d="M16 2.667A13.333 13.333 0 002.667 16c0 5.893 3.826 10.893 9.12 12.667.666.106.88-.307.88-.667v-2.253c-3.694.8-4.48-1.787-4.48-1.787-.614-1.547-1.48-1.96-1.48-1.96-1.214-.827.093-.8.093-.8 1.333.093 2.04 1.373 2.04 1.373C10 24.6 11.96 24 12.72 23.68c.12-.867.467-1.453.84-1.787-2.96-.333-6.067-1.48-6.067-6.56 0-1.48.507-2.666 1.374-3.613-.134-.333-.6-1.72.133-3.52 0 0 1.12-.36 3.667 1.36 1.053-.293 2.2-.44 3.333-.44s2.28.147 3.333.44C21.88 7.84 23 8.2 23 8.2c.733 1.8.267 3.187.133 3.52.867.947 1.374 2.133 1.374 3.613 0 5.094-3.12 6.214-6.094 6.547.48.413.92 1.227.92 2.467V28c0 .36.214.787.894.667C25.52 26.88 29.333 21.893 29.333 16A13.335 13.335 0 0016 2.667z"
                    ></path>
                  </svg>
                </a>
                <a href="https://www.behance.net/mattwolfe">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M22 7h-7V5h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14H15.97c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988H0V5.021h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zM3 11h3.584c2.508 0 2.906-3-.312-3H3v3zm3.391 3H3v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="about-tags-wrapper">
            <p style={{ color: "#06152c", fontWeight: 500 }}>Top Skills:</p>
            {skills.map((skill, i) => (
              <Tag
                key={Math.random()}
                primary={i > 3 ? false : true}
                data={skill}
              />
            ))}
          </div>
          <div className="about-tags-wrapper">
            <p style={{ color: "#06152c", fontWeight: 500, marginTop: "36px" }}>
              Top Languages & Frameworks:
            </p>
            {langs.map((skill, i) => (
              <Tag
                key={Math.random()}
                primary={i > 3 ? false : true}
                data={skill}
              />
            ))}
          </div>
        </div>
        <Icons className="about-background-icons" />
      </div>
    </SectionWrap>
  );
};

export default Section;
