import React from "react";

const Icon = (props) => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M5,9.5L7.5,14H2.5L5,9.5M3,4H7V8H3V4M5,20A2,2 0 0,0 7,18A2,2 0 0,0 5,16A2,2 0 0,0 3,18A2,2 0 0,0 5,20M9,5V7H21V5H9M9,19H21V17H9V19M9,13H21V11H9V13Z" />
    </svg>
  );
};

export default Icon;
