import React, { useState, useEffect } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

const Landing = (props) => {
  const [distanceFromTop, setDistanceFromTop] = useState(0);

  useEffect(() => {
    const handleScroll2 = () => {
      setDistanceFromTop(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll2, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll2);
  });

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -57;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return distanceFromTop > 54 ? null : (
    <div className="scroll-arrow">
      <NavHashLink smooth to="#about" scroll={(el) => scrollWithOffset(el)}>
        <svg width="58px" height="24px" viewBox="0 0 58 24" version="1.1">
          <title>See More</title>
          <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g
              transform="translate(-771.000000, -986.000000)"
              stroke="white"
              strokeWidth="3"
            >
              <g transform="translate(771.000000, 986.000000)">
                <polyline
                  id="download-arrow"
                  points="8 8 29 16 50 8"
                ></polyline>
              </g>
            </g>
          </g>
        </svg>
      </NavHashLink>
    </div>
  );
};

export default Landing;
