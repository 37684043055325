import React from "react";

import "./style.css";

const Headline = (props) => {
  return (
    <button
      className="mobile-menu-button flex-align-cent mobile-only"
      onClick={(e) => {
        props.drop(true);
      }}
    >
      <svg
        id="header-mobile-menu"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        height="24px"
        width="24px"
      >
        <path d="M18.35,6H3.05C2.58,6,2.2,6.45,2.2,7s0.38,1,0.85,1h15.3c0.47,0,0.85-0.45,0.85-1S18.82,6,18.35,6z"></path>
        <path d="M21.2,11h-18c-0.55,0-1,0.45-1,1s0.45,1,1,1h18c0.55,0,1-0.45,1-1S21.76,11,21.2,11z"></path>
        <path d="M13.2,16h-10c-0.5,0-1,0.45-1,1s0.5,1,1,1h10c0.5,0,1-0.45,1-1S13.7,16,13.2,16z"></path>
      </svg>
    </button>
  );
};

export default Headline;
