import React from "react";
import "./style.css";

const Logo = (props) => {
  const { height, width, className, color } = props;
  let grid = [];
  for (var i = 0; i < height; i++) {
    let row = new Array(width);
    for (var n = 0; n < height; n++) {
      row.push(n);
    }
    grid.push(row);
  }

  return (
    <div className={`${className ? className : ""} dot-wrapper`}>
      {grid.map((row, i) => {
        return (
          <div key={`row-dot-${i}`} className="dot-row flex-align-cent">
            {row.map((dot, ii) => (
              <div
                key={`indiv-dot-${ii}`}
                className="dot"
                style={{ background: color ? color : "white" }}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Logo;
