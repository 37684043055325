import React, { useState, useEffect, useRef } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

// components
import MobileButton from "../MobileButton";
import Logo from "../Logo";

const Header = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [underline, setUnderline] = useState(0);
  const [active, setActive] = useState(false);
  const [navArr, setNavArr] = useState([
    { name: "about", ref: useRef(null) },
    { name: "portfolio", ref: useRef(null) },
    { name: "services", ref: useRef(null) },
    { name: "clients", ref: useRef(null) },
  ]);

  const setBar = () => {
    if (navArr[0].ref) {
      let tempArr = navArr;
      let positionStore = 0;
      tempArr.forEach((item, i) => {
        let itemWidth = item.ref.current.getBoundingClientRect().width;

        tempArr[i].barWidth = 0.2 * itemWidth;
        tempArr[i].barPosition = positionStore + 0.4 * itemWidth;

        positionStore += itemWidth;
      });
      setNavArr(tempArr);
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!loaded) setBar();
    if (props.position) {
      if (props.position[4] < 200) return setActive(false);
      else if (props.position[3] < 200) setUnderline(3);
      else if (props.position[2] < 200) setUnderline(2);
      else if (props.position[1] < 200) setUnderline(1);
      else if (props.position[0] < 200) setUnderline(0);
      else {
        return setActive(false);
      }
      setActive(true);
    }
  }, [props.position]);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -57;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="header-clip-wrap">
      <header className={`flex-just-cent ${props.dark ? "dark-text" : ""}`}>
        <div className="header-content-wrap flex-just-btw flex-align-cent ">
          <MobileButton drop={props.drop} />
          <Logo />
          <nav className="desktop-only" style={{ position: "relative" }}>
            {navArr.map((item, i) => {
              return (
                <NavHashLink
                  key={`nav${i}-key`}
                  ref={navArr[i].ref}
                  to={`#${item.name}`}
                  smooth
                  activeClassName="header-link-selected"
                  scroll={(el) => scrollWithOffset(el)}
                >
                  {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                </NavHashLink>
              );
            })}
            <div
              className={`header-nav-underline ${active ? "active-tab" : ""}`}
              style={{
                width: `${
                  navArr[underline] ? navArr[underline].barWidth : 0
                }px`,
                transform: `translateX(
                  ${navArr[underline] ? navArr[underline].barPosition : 0}px)`,
              }}
            />
          </nav>

          <NavHashLink
            smooth
            to="#contact"
            scroll={(el) => scrollWithOffset(el)}
          >
            <button id="header-contact-button">
              <svg viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M13 17H17V14L22 18.5L17 23V20H13V17M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H11V18H4V8L12 13L20 8V14H22V6A2 2 0 0 0 20 4M12 11L4 6H20Z"
                />
              </svg>

              <div>Contact</div>
            </button>
          </NavHashLink>
        </div>
      </header>
    </div>
  );
};

export default Header;

// <svg viewBox="0 0 24 24">
//   <path
//     fill="currentColor"
//     d="M12 3C17.5 3 22 6.58 22 11C22 11.58 21.92 12.14 21.78 12.68C21.19 12.38 20.55 12.16 19.88 12.06C19.96 11.72 20 11.36 20 11C20 7.69 16.42 5 12 5C7.58 5 4 7.69 4 11C4 14.31 7.58 17 12 17L13.09 16.95L13 18L13.08 18.95L12 19C10.81 19 9.62 18.83 8.47 18.5C6.64 20 4.37 20.89 2 21C4.33 18.67 4.75 17.1 4.75 16.5C3.06 15.17 2.05 13.15 2 11C2 6.58 6.5 3 12 3M18 14H20V17H23V19H20V22H18V19H15V17H18V14Z"
//   />
// </svg>
//
