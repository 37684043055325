import React from "react";
import "./style.css";
import ImageFadeIn from "react-image-fade-in";

//comps
import Desktop from "../../../media/landing/Desktop.png";
import Mobile from "../../../media/landing/Phone.png";
import Desktopm from "../../../media/landing/Desktop-m.png";
import Mobilem from "../../../media/landing/Phone-m.png";
import Pazzaze from "../../../media/landing/pazazze.svg";

const Section = (props) => {
  return (
    <div className="landing-desktop-wrap">
      <div className=" mobile-landing">
        <ImageFadeIn className="desktop-landing-graph" src={Mobile} />
        <ImageFadeIn className="mobile-landing-graph" src={Mobilem} />
      </div>
      <div className="landing-desktop-pannel">
        <ImageFadeIn className="desktop-landing-graph" src={Desktop} />
        <ImageFadeIn className="mobile-landing-graph" src={Desktopm} />
      </div>
      <div className="desktop-pazzaze-wrapper">
        <svg
          id="desktop-pazzaze"
          width="863"
          height="671"
          viewBox="0 0 863 671"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M92.3954 623.589V618.194H87V616.395H92.3954V611H94.1939V616.395H99.5894V618.194H94.1939V623.589H92.3954Z"
            fill="white"
          />
          <path
            d="M7.19393 28.0005L7.19393 33.3959H12.5894V35.1944H7.19393V40.5898H5.39545V35.1944H0L0 33.3959H5.39545L5.39545 28.0005H7.19393Z"
            fill="white"
          />
          <path
            d="M797.799 371.992C797.799 370.084 798.556 368.255 799.906 366.906C801.255 365.556 803.084 364.798 804.992 364.798C806.9 364.798 808.73 365.556 810.079 366.906C811.428 368.255 812.186 370.084 812.186 371.992C812.186 373.9 811.428 375.73 810.079 377.079C808.73 378.428 806.9 379.186 804.992 379.186C803.084 379.186 801.255 378.428 799.906 377.079C798.556 375.73 797.799 373.9 797.799 371.992V371.992ZM813.985 371.992C813.985 370.812 813.752 369.642 813.3 368.551C812.848 367.46 812.186 366.469 811.351 365.634C809.665 363.947 807.377 363 804.992 363C802.608 363 800.32 363.947 798.634 365.634C797.799 366.469 797.136 367.46 796.685 368.551C796.233 369.642 796 370.812 796 371.992C796 374.377 796.947 376.665 798.634 378.351C800.32 380.037 802.608 380.985 804.992 380.985C806.173 380.985 807.343 380.752 808.434 380.3C809.525 379.848 810.516 379.186 811.351 378.351C812.186 377.516 812.848 376.525 813.3 375.434C813.752 374.343 813.985 373.173 813.985 371.992V371.992Z"
            fill="white"
          />
          <path
            d="M577.966 49.9924C577.966 48.0845 578.724 46.2547 580.074 44.9055C581.423 43.5564 583.252 42.7985 585.16 42.7985C587.068 42.7985 588.898 43.5564 590.247 44.9055C591.596 46.2547 592.354 48.0845 592.354 49.9924C592.354 51.9004 591.596 53.7302 590.247 55.0793C588.898 56.4284 587.068 57.1864 585.16 57.1864C583.252 57.1864 581.423 56.4284 580.074 55.0793C578.724 53.7302 577.966 51.9004 577.966 49.9924V49.9924ZM594.153 49.9924C594.153 48.8115 593.92 47.6422 593.468 46.5512C593.016 45.4602 592.354 44.4688 591.519 43.6338C589.833 41.9474 587.545 41 585.16 41C582.775 41 580.488 41.9474 578.802 43.6338C577.967 44.4688 577.304 45.4602 576.853 46.5512C576.401 47.6422 576.168 48.8115 576.168 49.9924C576.168 52.3774 577.115 54.6646 578.802 56.351C580.488 58.0374 582.775 58.9848 585.16 58.9848C586.341 58.9848 587.511 58.7522 588.602 58.3003C589.693 57.8484 590.684 57.186 591.519 56.351C592.354 55.516 593.016 54.5247 593.468 53.4337C593.92 52.3427 594.153 51.1733 594.153 49.9924V49.9924Z"
            fill="white"
          />
          <path
            d="M854.933 551.769V546.374H849.538V544.575H854.933V539.18H856.732V544.575H862.127V546.374H856.732V551.769H854.933Z"
            fill="white"
          />
          <path
            d="M318.985 243.329C318.985 242.148 318.752 240.979 318.3 239.888C317.848 238.797 317.186 237.806 316.351 236.971C314.665 235.284 312.377 234.337 309.992 234.337C307.608 234.337 305.32 235.284 303.634 236.971C302.799 237.806 302.136 238.797 301.685 239.888C301.233 240.979 301 242.148 301 243.329C301 245.714 301.947 248.002 303.634 249.688C305.32 251.374 307.608 252.322 309.992 252.322C311.173 252.322 312.343 252.089 313.434 251.637C314.525 251.185 315.516 250.523 316.351 249.688C317.186 248.853 317.848 247.862 318.3 246.771C318.752 245.68 318.985 244.51 318.985 243.329V243.329Z"
            fill="white"
          />
          <path
            d="M625.002 628.102V633.497H630.397V635.296H625.002V640.691H623.203V635.296H617.808V633.497H623.203V628.102H625.002Z"
            fill="white"
          />
          <path
            d="M206.853 335.848C206.853 337.756 206.095 339.586 204.746 340.935C203.397 342.284 201.567 343.042 199.659 343.042C197.751 343.042 195.922 342.284 194.573 340.935C193.223 339.586 192.465 337.756 192.465 335.848C192.465 333.94 193.223 332.111 194.573 330.762C195.922 329.412 197.751 328.654 199.659 328.654C201.567 328.654 203.397 329.412 204.746 330.762C206.095 332.111 206.853 333.94 206.853 335.848V335.848ZM190.667 335.848C190.667 337.029 190.9 338.199 191.351 339.29C191.803 340.381 192.466 341.372 193.301 342.207C194.987 343.893 197.274 344.841 199.659 344.841C202.044 344.841 204.332 343.893 206.018 342.207C206.853 341.372 207.515 340.381 207.967 339.29C208.419 338.199 208.652 337.029 208.652 335.848C208.652 333.463 207.704 331.176 206.018 329.49C204.332 327.803 202.044 326.856 199.659 326.856C198.479 326.856 197.309 327.089 196.218 327.54C195.127 327.992 194.136 328.655 193.301 329.49C192.466 330.325 191.803 331.316 191.351 332.407C190.9 333.498 190.667 334.668 190.667 335.848V335.848Z"
            fill="white"
          />
          <path
            d="M436.16 657.777C436.16 659.685 435.403 661.515 434.053 662.864C432.704 664.213 430.874 664.971 428.967 664.971C427.059 664.971 425.229 664.213 423.88 662.864C422.531 661.515 421.773 659.685 421.773 657.777C421.773 655.869 422.531 654.039 423.88 652.69C425.229 651.341 427.059 650.583 428.967 650.583C430.874 650.583 432.704 651.341 434.053 652.69C435.403 654.039 436.16 655.869 436.16 657.777V657.777ZM419.974 657.777C419.974 658.958 420.207 660.127 420.659 661.218C421.111 662.309 421.773 663.301 422.608 664.136C424.294 665.822 426.582 666.77 428.967 666.77C431.351 666.77 433.639 665.822 435.325 664.136C436.16 663.301 436.823 662.309 437.274 661.218C437.726 660.127 437.959 658.958 437.959 657.777C437.959 655.392 437.012 653.105 435.325 651.419C433.639 649.732 431.351 648.785 428.967 648.785C427.786 648.785 426.616 649.017 425.525 649.469C424.434 649.921 423.443 650.583 422.608 651.419C421.773 652.254 421.111 653.245 420.659 654.336C420.207 655.427 419.974 656.596 419.974 657.777V657.777Z"
            fill="white"
          />
          <path
            d="M239 600.992C239 602.173 239.233 603.342 239.685 604.433C240.136 605.524 240.799 606.516 241.634 607.351C243.32 609.037 245.607 609.984 247.992 609.984C250.377 609.984 252.665 609.037 254.351 607.351C255.186 606.516 255.848 605.524 256.3 604.433C256.752 603.342 256.985 602.173 256.985 600.992C256.985 598.607 256.037 596.32 254.351 594.633C252.665 592.947 250.377 592 247.992 592C246.812 592 245.642 592.232 244.551 592.684C243.46 593.136 242.469 593.798 241.634 594.633C240.799 595.468 240.136 596.46 239.685 597.551C239.233 598.642 239 599.811 239 600.992V600.992Z"
            fill="white"
          />
          <path
            d="M695.142 464.44C695.142 465.621 695.375 466.79 695.827 467.881C696.279 468.972 696.941 469.964 697.776 470.799C699.462 472.485 701.75 473.433 704.135 473.433C706.519 473.433 708.807 472.485 710.493 470.799C711.328 469.964 711.991 468.972 712.442 467.881C712.894 466.79 713.127 465.621 713.127 464.44C713.127 462.055 712.18 459.768 710.493 458.082C708.807 456.395 706.519 455.448 704.135 455.448C702.954 455.448 701.784 455.68 700.693 456.132C699.602 456.584 698.611 457.247 697.776 458.082C696.941 458.917 696.279 459.908 695.827 460.999C695.375 462.09 695.142 463.259 695.142 464.44V464.44Z"
            fill="white"
          />
          <path
            d="M833.985 210.992C833.985 209.812 833.752 208.642 833.3 207.551C832.848 206.46 832.186 205.469 831.351 204.634C829.665 202.947 827.377 202 824.992 202C822.608 202 820.32 202.947 818.634 204.634C817.799 205.469 817.136 206.46 816.685 207.551C816.233 208.642 816 209.812 816 210.992C816 213.377 816.947 215.665 818.634 217.351C820.32 219.037 822.608 219.985 824.992 219.985C826.173 219.985 827.343 219.752 828.434 219.3C829.525 218.848 830.516 218.186 831.351 217.351C832.186 216.516 832.848 215.525 833.3 214.434C833.752 213.343 833.985 212.173 833.985 210.992Z"
            fill="white"
          />
          <circle
            cx="512.5"
            cy="335.5"
            r="334.5"
            stroke="white"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
  );
};

export default Section;
