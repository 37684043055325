import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import "./style.css";

// import "../../comps/CardRow/Card/style.css";

//comps
import Header from "../../comps/Header";
import SectionWrap from "../../comps/SectionWrap";
import SectionHead from "../../comps/SectionHead";

//media
import Cart from "../../media/icons/Cart";
import Site from "../../media/icons/Site";
import Apps from "../../media/icons/Apps";
import Chart from "../../media/icons/Chart";
import Seo from "../../media/icons/SEO";
import Branding from "../../media/icons/Brangin";
import Database from "../../media/icons/Database";
import Api from "../../media/icons/API";
import Cloud from "../../media/icons/Cloud";
import Ml from "../../media/icons/ML";
import Security from "../../media/icons/Security";
import Consulting from "../../media/icons/Consulting";

const Section = (props) => {
  const [toggle, setToggle] = useState(false);
  const servArr = [
    {
      icon: Site,
      title: "Website",
      desc:
        "  Build a completely customizable website to your exact specifications.",
    },
    {
      icon: Cart,
      title: "Shopify Store",
      desc: "Craft the perfect Ecommerce store or Shoplifty application.",
    },
    {
      icon: Apps,
      title: "Mobile App",
      desc: "Create and Deploy an application on Android, IOS or as a PWA.",
    },
    {
      icon: Chart,
      title: "Marketing",
      desc:
        "Boost sales and conversions with an advances online marketing campaign.",
    },
    {
      icon: Seo,
      title: "SEO Ranking",
      desc:
        "Get to the top of Googles search results by improving your SEO ranking.",
    },
    {
      icon: Branding,
      title: "Branding & Logo",
      desc:
        "Stand out amongst your competitors with industry leading branding.",
    },
    {
      icon: Api,
      title: "APIs",
      desc: "Build highly adaptable APIs for your web and mobile applications.",
    },
    {
      icon: Ml,
      title: "Machine Learning",
      desc:
        "Train machine learning models to do almost anything you need them to do.",
    },
    {
      icon: Cloud,
      title: "Cloud Services",
      desc:
        "Integrate with or move your business to AWS, Azure, Google Cloud, or Firebase.",
    },
    {
      icon: Database,
      title: "Database",
      desc:
        "Upgrade your database to the best practices and most cutting-edge technologies.",
    },
    {
      icon: Security,
      title: "Security",
      desc:
        "Make sure your user’s data is safe and your Back-end solutions are secure.",
    },
    {
      icon: Consulting,
      title: "Consulting",
      desc: "Developing can get complicated, let me help you get the job done.",
    },
  ];

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -57;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <SectionWrap set={props.set} id="services" className="flex-just-cent ">
      <Header position={props.position} drop={props.drop} />
      <div>
        <SectionHead text="Common" hilght="Services" />
        <p style={{ maxWidth: "600px", margin: "24px auto 12px" }}>
          With quite a few projects under my belt there is little I have not
          worked on. Below are some of the more common services I offer. If you
          do not see what you are looking for, do not hesitate to inquire!
        </p>
        <div style={{ maxWidth: "300px", margin: "0 auto", cursor: "pointer" }}>
          <div
            className="flex-align-cent flex-just-cent"
            onClick={(e) => setToggle(!toggle)}
          >
            <h3 className={!toggle ? "active-service" : ""}>Front-end</h3>
            <label className="switch">
              <input
                type="checkbox"
                checked={toggle}
                onClick={(e) => {
                  console.log(toggle);
                }}
              />
              <span className="slider round" />
            </label>
            <h3 className={toggle ? "active-service" : ""}>Back-end</h3>
          </div>
        </div>
        <div className="flex-just-cent">
          <div className="services-grid">
            {servArr.map((service, i) => {
              if (!toggle & (i > 5)) return;
              if (toggle & (i < 6)) return;
              let MyComp = service.icon ? service.icon : Site;

              return (
                <HashLink
                  smooth
                  to="#contact"
                  className="service-card"
                  key={`service-card-${i}`}
                  onClick={(e) => props.setSubject(service.title)}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  <div className="flex-just-cent flex-align-top services-svg-wrap">
                    <div>
                      <MyComp />
                    </div>
                  </div>
                  <h1>{service.title}</h1>
                  <p>{service.desc}</p>
                </HashLink>
              );
            })}
          </div>
        </div>
        <HashLink smooth to="#contact" scroll={(el) => scrollWithOffset(el)}>
          <button className="services-more-button">
            Have something else in mind?
          </button>
        </HashLink>
      </div>
    </SectionWrap>
  );
};

export default Section;
