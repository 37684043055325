import React, { useLayoutEffect, useRef, useState } from "react";
import "./style.css";

//comps
import Header from "../../comps/Header";
import CardRow from "../../comps/CardRow";
import Dots from "../../comps/Dots";
import SectionWrap from "../../comps/SectionWrap";
import Car from "../../comps/Carosel";
import NewCar from "../../comps/NewNewCarosel";

//media
import VwStakingTile from "../../media/vwMiningTile.jpg";
import BaselayerTile from "../../media/baselayerTile.jpg";
import FlipitTile from "../../media/FlipitTile.jpg";
import CCTile from "../../media/creatingCalligraphy.png";
import EVaultTile from "../../media/eVult.png";
import QRCoachTile from "../../media/qrCoach.png";
import WolfeADTile from "../../media/wolfeAD.png";
import vwMiningTile from "../../media/vwMining.png";
import WalletTile from "../../media/wallet.png";

const Section = (props) => {
  let projects = [
    {
      img: VwStakingTile,
      alt: "VW Staking",
      link: "https://www.behance.net/gallery/97866643/VW-Staking",
    },
    {
      img: BaselayerTile,
      alt: "BaseLayer Blockchain",
      link: "https://www.behance.net/gallery/98052603/BASElayer",
    },

    {
      img: FlipitTile,
      alt: "Flipit",
      link: "https://www.behance.net/gallery/98052053/Flipit",
    },
    {
      img: WalletTile,
      alt: "Wallet",
      link: "https://www.behance.net/gallery/98052599/Wallet",
    },

    {
      img: vwMiningTile,
      alt: "VW Mining",
      link: "https://www.behance.net/gallery/98052449/VW-Mining-Terminal",
    },

    {
      img: EVaultTile,
      alt: "EVault",
      link: "https://www.behance.net/gallery/97859979/EVault",
    },

    {
      img: WolfeADTile,
      alt: "Wolfe Architecture & Design",
      link: "https://www.behance.net/gallery/97860011/Wolfe-A-D",
    },

    {
      img: CCTile,
      alt: "Creating Calligraphy",
      link: "https://www.behance.net/gallery/97860411/Creating-Calligraphy",
    },

    {
      img: QRCoachTile,
      alt: "QR Coach",
      link: "https://www.behance.net/gallery/97867351/QR-Coach",
    },
  ];

  const [social, setSocial] = useState(false);
  const ref1 = useRef(null);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const socialPos = topPos(ref1.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + 0.8 * window.innerHeight;
      if (socialPos < scrollPos) setSocial(true);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <SectionWrap
      set={props.set}
      id="portfolio"
      className="blue-section flex-just-cent"
    >
      <Header position={props.position} drop={props.drop} />
      <div>
        <div>
          <span className="profile-title profile-title-white">
            Check out my <h1 style={{ color: "white" }}>Portfolio</h1>
          </span>
          <div
            ref={ref1}
            className={`flex-align-cent social-wrapper ${
              social ? "show-socials" : ""
            }`}
          >
            <a href="https://www.behance.net/mattwolfe">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fill="#fff"
                  d="M22 7h-7V5h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14H15.97c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988H0V5.021h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zM3 11h3.584c2.508 0 2.906-3-.312-3H3v3zm3.391 3H3v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"
                ></path>
              </svg>
            </a>
            <a href="https://dribbble.com/mwolfe">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M16 3C8.823 3 3 8.823 3 16s5.823 13 13 13c7.163 0 13-5.823 13-13S23.163 3 16 3zm8.587 5.992c1.55 1.89 2.481 4.3 2.51 6.91-.367-.071-4.033-.818-7.727-.353-.085-.183-.155-.38-.24-.578a33.027 33.027 0 00-.733-1.608c4.089-1.663 5.95-4.06 6.19-4.37zM16 4.918c2.82 0 5.4 1.057 7.36 2.791-.197.282-1.875 2.524-5.823 4.005-1.819-3.342-3.835-6.077-4.145-6.5A11.292 11.292 0 0116 4.918zM11.277 5.96c.296.395 2.27 3.144 4.117 6.415-5.189 1.382-9.771 1.354-10.265 1.354.72-3.44 3.046-6.303 6.148-7.769zM4.889 16.014v-.338c.48.014 5.866.084 11.407-1.58.324.62.62 1.255.902 1.89-.14.042-.296.084-.437.127-5.724 1.847-8.77 6.895-9.023 7.318a11.078 11.078 0 01-2.849-7.417zM16 27.111c-2.566 0-4.935-.875-6.81-2.34.197-.41 2.453-4.753 8.714-6.938.028-.014.042-.014.07-.028 1.565 4.046 2.2 7.444 2.369 8.417a10.92 10.92 0 01-4.343.889zm6.19-1.904c-.113-.677-.705-3.92-2.158-7.91 3.483-.55 6.529.353 6.91.48-.48 3.087-2.256 5.752-4.752 7.43z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <a href="https://github.com/mwoof">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#fff"
                  d="M16 2.667A13.333 13.333 0 002.667 16c0 5.893 3.826 10.893 9.12 12.667.666.106.88-.307.88-.667v-2.253c-3.694.8-4.48-1.787-4.48-1.787-.614-1.547-1.48-1.96-1.48-1.96-1.214-.827.093-.8.093-.8 1.333.093 2.04 1.373 2.04 1.373C10 24.6 11.96 24 12.72 23.68c.12-.867.467-1.453.84-1.787-2.96-.333-6.067-1.48-6.067-6.56 0-1.48.507-2.666 1.374-3.613-.134-.333-.6-1.72.133-3.52 0 0 1.12-.36 3.667 1.36 1.053-.293 2.2-.44 3.333-.44s2.28.147 3.333.44C21.88 7.84 23 8.2 23 8.2c.733 1.8.267 3.187.133 3.52.867.947 1.374 2.133 1.374 3.613 0 5.094-3.12 6.214-6.094 6.547.48.413.92 1.227.92 2.467V28c0 .36.214.787.894.667C25.52 26.88 29.333 21.893 29.333 16A13.335 13.335 0 0016 2.667z"
                ></path>
              </svg>
            </a>
          </div>

          <div
            className="portfolio-card-wrapper"
            style={{ marginBottom: "-24px" }}
          >
            <Car direction={1} cards={projects} />
            <Car direction={-1} cards={projects} />
          </div>

          <div className="flex-just-rit portfolio-button-wrap">
            <a
              style={{ textDecoration: "none" }}
              href="https://www.behance.net/mattwolfe"
            >
              <button className="flex-align-cent">
                Full Portfolio
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>

        <Dots className="portfolio-dots-1" height={8} width={8} />
        <Dots className="portfolio-dots-2" height={8} width={8} />
      </div>
    </SectionWrap>
  );
};

export default Section;

// <CardRow direction={1} cards={projects} />
// <CardRow direction={-1} cards={projects} />
