import React, { useState, useEffect } from "react";
import "./style.css";

import Confetti from "react-confetti";

import firebase from "../../firebase";
import "firebase/analytics";

//comps
import Header from "../../comps/Header";
import SectionWrap from "../../comps/SectionWrap";
import SectionHead from "../../comps/SectionHead";

const db = firebase.firestore();

const Section = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const col = ["#2A46FF", "#3A55FE", "#D0D2D5", "#9DA5AF", "#FFFFFF"];
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const submitMssg = (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");

    // console.log("sent");
    // firebase.analytics().logEvent("Message_Sent");

    db.collection("Stats")
      .doc("overview")
      .get()
      .then((doc) => {
        let cnt = doc.data().msgCount;
        let id = `MSG#${cnt}-${Math.random()}`;

        db.collection("Messages").doc(id).set({
          name: name,
          email: email,
          subject: props.subject,
          text: text,
          subscribe: subscribe,
        });
      })
      .then((doc) => {
        setSent(true);
        setLoading(false);
        setName("");
        setEmail("");
        setText("");
        setError("");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
        setLoading(false);
        setError(error.message);
      });
  };

  useEffect(() => {
    if (sent) {
      setTimeout(function () {
        setSent(false);
      }, 10000);
    }
  }, [sent]);

  return (
    <SectionWrap set={props.set} id="contact" className="flex-just-cent">
      {!sent ? null : (
        <div style={{ zIndex: 5 }} className={!sent ? "" : "sent-animation"}>
          <Confetti
            width={windowDimensions.width}
            height={windowDimensions.height}
            colors={col}
          />
        </div>
      )}
      <Header position={props.position} drop={props.drop} />
      <div style={{ zIndex: 6 }}>
        <SectionHead text="Let's Work" hilght="Together" />
        <p
          style={{
            maxWidth: "600px",
            margin: "24px auto 0",
            textAlign: "center",
          }}
        >
          Ready to get started or want to learn more? Please fill out the
          following form or email me{" "}
          <a
            href="mailto:Matt Wolfe <mwatt@mwolfe.io>"
            style={{ textDecoration: "none" }}
          >
            here
          </a>
          .
        </p>
        <form onSubmit={(e) => submitMssg(e)}>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your name..."
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your email..."
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="Subject..."
            required
            value={props.subject}
            onChange={(e) => props.setSubject(e.target.value)}
          />
          <textarea
            id="text"
            name="text"
            placeholder="What are you thinking..."
            required
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <div
            className="flex-align-cent flex-just-lft subscribe-check-wrap"
            onClick={(e) => setSubscribe(!subscribe)}
          >
            {subscribe ? (
              <svg viewBox="0 0 24 24">
                <path
                  fill="#2a46ff"
                  d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
                />
              </svg>
            ) : (
              <svg viewBox="0 0 24 24">
                <path
                  fill="#9da5af"
                  d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
                />
              </svg>
            )}
            <p>I would like to receive deals and promotions.</p>
          </div>
          <div style={{ textAlign: "right" }}>
            {!error ? "" : <p style={{ color: "red" }}>{error}</p>}
            <button type="submit">{!loading ? "Submit" : "Loading..."}</button>
          </div>
        </form>
      </div>
    </SectionWrap>
  );
};

export default Section;
