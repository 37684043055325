import React, { useEffect, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import Typewriter from "typewriter-effect";

import "./style.css";

const Section = (props) => {
  const arr = [
    "Websites",
    "applications",
    "Marketing Campaigns",
    "Brands",
    "Ecommerce Stores",
    "ML Models",
    "APIs",
    "Databases",
  ];

  arr.sort(() => 0.5 - Math.random());

  const [count, setCount] = useState(0);
  const [project, setProject] = useState(arr[count]);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -57;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  //Fast and affordable Web, App, and Software development.

  return (
    <div className="landing-header-wrapper">
      <div>
        <h3 className="blue-shadow">
          We Build <br />
          <span className="landing-header-project">
            <Typewriter
              options={{
                strings: arr,
                autoStart: true,
                loop: true,
              }}
            />
          </span>
        </h3>
        <h2 className="blue-shadow">
          Full-stack Web, App, and Software development.
        </h2>
      </div>
      <div className="flex-align-cent landing-buttons">
        <NavHashLink smooth to="#contact" scroll={(el) => scrollWithOffset(el)}>
          <button className="">Get Started</button>
        </NavHashLink>
        <NavHashLink
          smooth
          to="#services"
          scroll={(el) => scrollWithOffset(el)}
        >
          <button className="button-no-back">View Services</button>{" "}
        </NavHashLink>
      </div>
    </div>
  );
};

export default Section;
