import React from "react";
import "./style.css";

const Section = (props) => {
  return (
    <div className="section-header-wrap flex-align-cent">
      <span>
        {props.text} <h1>{props.hilght}</h1>
      </span>
      <div />
    </div>
  );
};

export default Section;
