import React from "react";
import "./style.css";

//comps
import Header from "../../comps/Header";
import Hint from "../../comps/ScrollHint";
import LandingGraphic from "../../comps/LandingGraphic";

const Section = (props) => {
  return (
    <section
      id="home"
      className="blue-section fullscreen-section flex-just-cent"
    >
      <Header position={props.position} drop={props.drop} />
      <div>
        <LandingGraphic />
        <div className="landing-background-text-wrap flex-align-cent">
          <h1 className="landing-background-text">
            Apps
            <br />
            Websites
            <br />
            Graphic Design
            <br />
            Marketing
          </h1>
        </div>
        <Hint />
      </div>
    </section>
  );
};

export default Section;
