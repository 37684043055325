import React from "react";
import "./style.css";

const Logo = (props) => {
  return (
    <div
      className={`about-tag ${props.primary ? "about-primary-tag" : ""} ${
        props.data.des != "MORE" ? "" : "no-outline"
      } ${props.data.des ? "" : "no-tooltip"}`}
    >
      <h1>{props.data.title}</h1>
      {props.data.des && props.data.des != "MORE" ? (
        <div className="tag-tool-tip">{props.data.des}</div>
      ) : null}
    </div>
  );
};

export default Logo;
