import React from "react";
import { NavHashLink } from "react-router-hash-link";

import "./style.css";

const Headline = (props) => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -57;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <div
      className={`mobile-dropdown-wrapper mobile-only ${
        props.drop ? "nav-extended" : ""
      }`}
      onClick={(e) => {
        console.log("click");
        props.setDrop(false);
      }}
    >
      <nav>
        <NavHashLink
          to="#about"
          smooth
          activeClassName="header-link-selected"
          scroll={(el) => scrollWithOffset(el)}
        >
          About
        </NavHashLink>
        <NavHashLink
          to="#portfolio"
          smooth
          activeClassName="header-link-selected"
          scroll={(el) => scrollWithOffset(el)}
        >
          Portfolio
        </NavHashLink>
        <NavHashLink
          to="#services"
          smooth
          activeClassName="header-link-selected"
          scroll={(el) => scrollWithOffset(el)}
        >
          Services
        </NavHashLink>
        <NavHashLink
          to="#clients"
          smooth
          activeClassName="header-link-selected"
          scroll={(el) => scrollWithOffset(el)}
        >
          Clients
        </NavHashLink>
        <NavHashLink
          to="#contact"
          smooth
          activeClassName="header-link-selected"
          className="special-header-link-selected"
          scroll={(el) => scrollWithOffset(el)}
        >
          Contact
        </NavHashLink>
      </nav>
    </div>
  );
};

export default Headline;
